import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const checkGradingStatus = (token: string, data: object) => {
    console.log("Checking grading status for..");
    console.log(data);
    return axios
        .post(`api/grading`, data, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'Retrieved grading data'),
            handleResponseError,
        );
};
