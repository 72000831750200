import { Box, Button, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TextareaAutosize } from '@mui/material';
import React, { useContext, useEffect } from 'react';

import RestrictedByRole from '../../components/restricted-by-role';
import { checkGradingStatus } from '../../http-actions/grading-actions';

import { AuthContext } from '../../providers/auth-provider';

const isUuid = (str: string) => {
    return str.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'); // TODO: move to util file (e.g. string-util.tsx)
}

const DAD_MODULES = ["01-overview-and-practicalities", "02-cross-device-interaction", "03-dart-basics", "04-dart-classes-and-objects", "05-flutter-basics", "06-flutter-navigation", "07-flutter-state-management", "08-flutter-handling-input", "09-futures-and-working-with-apis", "10-local-development-environment", "11-flutter-storing-and-retrieving-data", "12-flutter-testing-applications", "14-course-project-i", "17-flutter-responsive-layouts", "18-flutter-flexible-lists-and-grids", "19-design-systems-and-usability", "20-theming-applications", "21-working-with-gestures", "22-flutter-and-firebase", "23-course-project-ii", "24-extras"];
const DAB_MODULES = ["dab-01-introduction-and-tooling", "dab-02-web-software-development-rehearsal", "dab-03-defining-scalability", "dab-04-measuring-performance", "dab-05-multiple-servers-and-load-balancing", "dab-06-caching", "dab-07-client-side-web-development", "dab-08-svelte", "dab-09-astro", "dab-10-tailwind", "dab-11-real-time-communication", "dab-12-event-driven-architecture", "dab-13-production-configuration", "dab-14-course-project-i", "dab-15-content-delivery-networks", "dab-16-data-and-scalability", "dab-17-container-orchestration", "dab-18-scaling-with-kubernetes", "dab-19-monitoring-applications", "dab-20-cloud-computing", "dab-21-serverless-computing", "dab-22-cloud-architecture-patterns", "dab-23-a-peek-at-protocols", "dab-24-course-project-ii", "dab-25-beyond-software-scalability"];
const WSD_MODULES = ["1-introduction-and-tooling", "2-hypertext-markup-language", "3-cascading-style-sheets", "5-internet-and-http", "6-javascript-primer", "7-first-web-applications", "8-deployment-i", "9-working-with-containers", "10-view-templates", "11-deployment-ii", "12-forms-and-data", "13-working-with-databases-i", "14-working-with-databases-2", "15-deployment-iii", "16-web-application-architecture-i", "19-testing-web-applications-i", "20-course-project-i", "21-introduction-to-oak-web-framework", "22-routes-and-view-templates", "23-forms-and-databases-with-oak", "24-cookies-and-sessions", "25-authentication-and-authorization", "26-data-validation", "27-application-programming-interfaces", "28-testing-web-applications", "30-handling-binary-content", "31-client-side-javascript", "32-web-security-basics", "33-web-application-architecture-ii", "35-course-project-ii"];
const WSD2_MODULES = ["10-the-crud","2-internet-and-http","11-data-validation","30-client-side-api-endpoints","12-cookies-and-sessions","31-client-side-authentication","13-authentication-and-authorization","32-client-side-application-structure","14-user-management","33-end-to-end-testing","15-middlewares","34-web-security-basics","16-user-specific-data","35-deployment-and-docker","17-application-structure","36-other-frameworks-and-languages","18-testing-applications","3-first-web-applications","19-evolution-of-web-development","40-javascript-primer","1-overview-and-practicalities","4-hono-web-framework","20-application-containerization","5-deployment","21-working-with-databases","6-data-on-server","22-application-programming-interfaces","7-hypertext-markup-language","23-javascript-on-the-client","8-view-templates","24-introduction-to-svelte","99-requesting-credits","25-svelte-components","9-forms-and-data","26-svelte-state-management","27-svelte-interacting-with-apis","28-cascading-style-sheets","29-svelte-layouts-pages-and-styles"];


const projectStats = (data, user, projectModule) => {
    const givenReviewDetails = data.givenReviews.filter(e => e.userUuid === user && e.module === projectModule);
    const receivedPeerReviewDetails = data.receivedPeerReviews.filter(e => e.userUuid === user && e.module === projectModule);
    const receivedAdminReviewDetails = data.receivedAdminReviews.filter(e => e.userUuid === user && e.module === projectModule);

    let givenReviews = givenReviewDetails.length > 0 ? givenReviewDetails[0].givenPeerReviews : 0;

    let givenSelfReviews = givenReviewDetails.length > 0 ? Math.round(10 * givenReviewDetails[0].givenSelfReviews) / 10 : 0;
    let givenSelfReviewPoints = givenReviewDetails.length > 0 ? Math.round(10 * givenReviewDetails[0].avgGivenSelfReviewPoints) / 10 : 0;

    let receivedPeerReviews = receivedPeerReviewDetails.length > 0 ? receivedPeerReviewDetails[0].receivedPeerReviews : 0;
    let receivedPeerReviewAvgPoints = receivedPeerReviewDetails.length > 0 ? Math.round(10 * receivedPeerReviewDetails[0].avgReceivedPeerReviewPoints) / 10 : 0;

    let receivedAdminReviews = receivedAdminReviewDetails.length > 0 ? receivedAdminReviewDetails[0].receivedPeerReviews : 0;
    let receivedAdminReviewAvgPoints = receivedAdminReviewDetails.length > 0 ? Math.round(10 * receivedAdminReviewDetails[0].avgReceivedPeerReviewPoints) / 10 : 0;

    return {
        givenReviews: givenReviews,
        givenSelfReviews: givenSelfReviews,
        givenSelfReviewPoints: givenSelfReviewPoints,
        receivedPeerReviews: receivedPeerReviews,
        receivedPeerReviewAveragePoints: receivedPeerReviewAvgPoints,
        receivedAdminReviews: receivedAdminReviews,
        receivedAdminReviewPoints: receivedAdminReviewAvgPoints,
    };
}

const reviews = (data, user, projectModule) => {
    const stats = projectStats(data, user, projectModule);
    return `${stats.givenReviews} / ${stats.givenSelfReviewPoints} / ${stats.receivedPeerReviews} / ${stats.receivedPeerReviewAveragePoints} / ${stats.receivedAdminReviews} / ${stats.receivedAdminReviewPoints}`;
}

const percentageCompleted = (module, points) => {
    if (!points || points === 0) {
        return 0;
    }

    let maxPoints = 1;

    if (module === 'data-and-information') {
        maxPoints = 1605;
    } else if (module === 'mobile-application-development') {
        maxPoints = 1555;
    } else if (module === 'introduction-to-programming') {
        maxPoints = 1330;
    } else if (module === 'internet-and-browser-applications') {
        maxPoints = 1095;
    } else if (module === 'web-software-development-1-0') {
        maxPoints = 5647;
    } else if (module === 'web-software-development') {
        maxPoints = 2890;
    } else if (module === 'device-agnostic-design') {
        maxPoints = 3040;
    } else if (module === 'designing-and-building-scalable-web-applications') {
        maxPoints = 3225;
    } else if (module === 'rust') {
        maxPoints = 3600;
    } else {
        maxPoints = 99999;
    }

    return Math.ceil(100 * points / maxPoints);
}


const DeviceAgnosticDesignSummaryTable = (props) => {
    if (!props || !props.data || !props.data.points) {
        return (<></>);
    }

    if (!Array.isArray(props.data.points)) {
        return (<>Missing or invalid data</>);
    }

    const dadPoints = props.data.points.filter(e => e.moduleCollection === 'Device-Agnostic Design' && DAD_MODULES.includes(e.module));

    const userPointSum = new Map();
    dadPoints.forEach((e) => {
        if(!userPointSum.has(e.userUuid)) {
            userPointSum.set(e.userUuid, 0);
        }

        userPointSum.set(e.userUuid, userPointSum.get(e.userUuid) + parseInt(e.points));
    });

    const seenStudentUuids = new Set();

    return (
        <>
            <Typography variant="h2">
                Device-Agnostic Design
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Percentage</TableCell>
                            <TableCell>P1</TableCell>
                            <TableCell>P2</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dadPoints.filter(row => {
                            if (seenStudentUuids.has(row.userUuid)) {
                                return false;
                            }

                            seenStudentUuids.add(row.userUuid)
                            return true;
                        }).map((row) => (
                            <TableRow key={row.userUuid + "-" + row.moduleCollection}>
                                <TableCell>{row.userId}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.userUuid}</TableCell>
                                <TableCell>{userPointSum.get(row.userUuid)}</TableCell>
                                <TableCell>{percentageCompleted('device-agnostic-design', userPointSum.get(row.userUuid))}%</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, '14-course-project-i')}</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, '23-course-project-ii')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const DesigningAndBuildingScalableWebApplicationsSummaryTable = (props) => {
    if (!props || !props.data || !props.data.points) {
        return (<></>);
    }

    if (!Array.isArray(props.data.points)) {
        return (<>Missing or invalid data</>);
    }


    const dabPoints = props.data.points.filter(e => e.moduleCollection === "Designing and Building Scalable Web Applications" && DAB_MODULES.includes(e.module));

    const userPointSum = new Map();
    dabPoints.forEach((e) => {
        if(!userPointSum.has(e.userUuid)) {
            userPointSum.set(e.userUuid, 0);
        }

        userPointSum.set(e.userUuid, userPointSum.get(e.userUuid) + parseInt(e.points));
    });

    const seenStudentUuids = new Set();

    return (
        <>
            <Typography variant="h2">
                Designing and Building Scalable Web Applications
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Percentage</TableCell>
                            <TableCell>P1</TableCell>
                            <TableCell>P2</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dabPoints.filter(row => {
                            if (seenStudentUuids.has(row.userUuid)) {
                                return false;
                            }

                            seenStudentUuids.add(row.userUuid)
                            return true;
                        }).map((row) => (
                            <TableRow key={row.userUuid + "-" + row.moduleCollection}>
                                <TableCell>{row.userId}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.userUuid}</TableCell>
                                <TableCell>{userPointSum.get(row.userUuid)}</TableCell>
                                <TableCell>{percentageCompleted('designing-and-building-scalable-web-applications', userPointSum.get(row.userUuid))}%</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, 'dab-14-course-project-i')}</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, 'dab-24-course-project-ii')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const WebSoftwareDevelopment20SummaryTable = (props) => {
    if (!props || !props.data || !props.data.points) {
        return (<></>);
    }

    if (!Array.isArray(props.data.points)) {
        return (<>Missing or invalid data</>);
    }

    const wsdPoints = props.data.points.filter(e => e.moduleCollection === 'Web Software Development' && WSD2_MODULES.includes(e.module));

    const userPointSum = new Map();
    wsdPoints.forEach((e) => {
        if(!userPointSum.has(e.userUuid)) {
            userPointSum.set(e.userUuid, 0);
        }

        userPointSum.set(e.userUuid, userPointSum.get(e.userUuid) + parseInt(e.points));
    });

    const seenStudentUuids = new Set();

    return (
        <>
            <Typography variant="h2">
                Web Software Development
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Percentage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wsdPoints.filter(row => {
                            if (seenStudentUuids.has(row.userUuid)) {
                                return false;
                            }

                            seenStudentUuids.add(row.userUuid)
                            return true;
                        }).map((row) => (
                            <TableRow key={row.userUuid + "-" + row.moduleCollection}>
                                <TableCell>{row.userId}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.userUuid}</TableCell>
                                <TableCell>{userPointSum.get(row.userUuid)}</TableCell>
                                <TableCell>{percentageCompleted('web-software-development', userPointSum.get(row.userUuid))}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}


const WebSoftwareDevelopmentSummaryTable = (props) => {
    if (!props || !props.data || !props.data.points) {
        return (<></>);
    }

    if (!Array.isArray(props.data.points)) {
        return (<>Missing or invalid data</>);
    }

    const wsdPoints = props.data.points.filter(e => e.moduleCollection === 'Web Software Development 1.0' && WSD_MODULES.includes(e.module));

    const userPointSum = new Map();
    wsdPoints.forEach((e) => {
        if(!userPointSum.has(e.userUuid)) {
            userPointSum.set(e.userUuid, 0);
        }

        userPointSum.set(e.userUuid, userPointSum.get(e.userUuid) + parseInt(e.points));
    });

    const seenStudentUuids = new Set();

    return (
        <>
            <Typography variant="h2">
                Web Software Development
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Percentage</TableCell>
                            <TableCell>P1</TableCell>
                            <TableCell>P2</TableCell>
                            <TableCell>Old P1</TableCell>
                            <TableCell>Old P2</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wsdPoints.filter(row => {
                            if (seenStudentUuids.has(row.userUuid)) {
                                return false;
                            }

                            seenStudentUuids.add(row.userUuid)
                            return true;
                        }).map((row) => (
                            <TableRow key={row.userUuid + "-" + row.moduleCollection}>
                                <TableCell>{row.userId}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.userUuid}</TableCell>
                                <TableCell>{userPointSum.get(row.userUuid)}</TableCell>
                                <TableCell>{percentageCompleted('web-software-development-1-0', userPointSum.get(row.userUuid))}%</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, '20-course-project-i')}</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, '35-course-project-ii')}</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, '36-old-course-project-i')}</TableCell>
                                <TableCell>{reviews(props.data, row.userUuid, '37-old-course-project-ii')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}


const FITechPointsTable = (props) => {
    if (!props || !props.data || !props.data.points) {
        return (<></>);
    }

    if (!Array.isArray(props.data.points)) {
        return (<>Missing or invalid data</>);
    }

    return (
        <>
            <Typography variant="h2">
                FITech 101
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Module</TableCell>
                            <TableCell>Pass</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Percentage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.points.filter(e => e.moduleCollection == 'FITech 101: Digi & Data').map((row) => (
                            <TableRow key={row.userUuid + "-" + row.module}>
                                <TableCell>{row.userId}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.userUuid}</TableCell>
                                <TableCell>{row.module}</TableCell>
                                <TableCell>{percentageCompleted(row.module, row.points) >= 90 ? 'Yes': 'No'}</TableCell>
                                <TableCell>{row.points}</TableCell>
                                <TableCell>{percentageCompleted(row.module, row.points)}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}



const ProgrammingLanguagesPointsTable = (props) => {
    if (!props || !props.data || !props.data.points) {
        return (<></>);
    }

    if (!Array.isArray(props.data.points)) {
        return (<>Missing or invalid data</>);
    }

    return (
        <>
            <Typography variant="h2">
                ProgrammingLanguages
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Module</TableCell>
                            <TableCell>Pass</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Percentage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.points.filter(e => e.moduleCollection == 'Modern and Emerging Programming Languages').map((row) => (
                            <TableRow key={row.userUuid + "-" + row.module}>
                                <TableCell>{row.userId}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.userUuid}</TableCell>
                                <TableCell>{row.module}</TableCell>
                                <TableCell>{percentageCompleted(row.module, row.points) >= 90 ? 'Yes': 'No'}</TableCell>
                                <TableCell>{row.points}</TableCell>
                                <TableCell>{percentageCompleted(row.module, row.points)}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const CourseGradingPage = () => {
    const { state: authState } = useContext(AuthContext);
    const [codes, setCodes] = React.useState("");
    const [error, setError] = React.useState("");
    const [gradingData, setGradingData] = React.useState(null);
    const [showWebSoftwareDevelopment, setShowWebSoftwareDevelopment] = React.useState(true);
    const [showDeviceAgnosticDesign, setShowDeviceAgnosticDesign] = React.useState(true);
    const [showDesigningAndBuildingScalableWebApplications, setShowDesigningAndBuildingScalableWebApplications] = React.useState(true);
    const [showFitech, setShowFitech] = React.useState(true);
    const [showProgrammingLanguages, setShowProgrammingLanguages] = React.useState(true);
    const [showWsd2, setShowWsd2] = React.useState(true);


    useEffect(() => {
        if (!authState.token) {
            console.log('No token - no access');
            return;
        }

    }, [authState.token]);


    const checkStatus = async () => {
        if (!authState.token) {
            console.log('No token - no access');
            return;
        }

        setError("");

        console.log("Checking status for");
        const data = {
            'user_uuids': codes.trim().split(/\s+/).filter(s => s.length > 0)
        };

        if (data.user_uuids.filter(u => !isUuid(u)).length > 0) {
            setError("Invalid codes: " + data.user_uuids.filter(u => !isUuid(u)));
            return;
        }

        if (data.user_uuids.length <= 0) {
            console.log('Nothing to check');
            setGradingData(null);
            return;
        }

        console.log('Sending data:');
        console.log(data);
        const gradingStatusResponse = await checkGradingStatus(authState.token, data);
        console.log('Received data:')
        console.log(gradingStatusResponse);
        setGradingData(gradingStatusResponse);
    }

    return (
        <>
            <Typography variant="h2">
                Paste codes here and press check status
            </Typography>
            <TextareaAutosize
                aria-label="Paste codes here, one per row"
                minRows={5}
                maxRows={25}
                placeholder="Paste codes here, one per row"
                onChange={(e) => {setCodes(e.target.value)}}
                style={{ width: "100%" }}
            />
            <FormControlLabel
                control={<Checkbox checked={showDeviceAgnosticDesign} onChange={(e) => setShowDeviceAgnosticDesign(e.target.checked)} name="show-dad" />}
                label="Show Device-Agnostic Design"
            />
            <FormControlLabel
                control={<Checkbox checked={showWebSoftwareDevelopment} onChange={(e) => setShowWebSoftwareDevelopment(e.target.checked)} name="show-wsd" />}
                label="Show Web Software Development"
            />
            <FormControlLabel
                control={<Checkbox checked={showDesigningAndBuildingScalableWebApplications} onChange={(e) => setShowDesigningAndBuildingScalableWebApplications(e.target.checked)} name="show-dab" />}
                label="Show Designing and Building Scalable Web Applications"
            />
            <FormControlLabel
                control={<Checkbox checked={showFitech} onChange={(e) => setShowFitech(e.target.checked)} name="show-fitech" />}
                label="Show FITech courses"
            />
            <FormControlLabel
                control={<Checkbox checked={showProgrammingLanguages} onChange={(e) => setShowProgrammingLanguages(e.target.checked)} name="show-proglang" />}
                label="Show Programming Languages"
            />
            <FormControlLabel
                control={<Checkbox checked={showWsd2} onChange={(e) => setShowWsd2(e.target.checked)} name="show-wsd2" />}
                label="Show WSD2"
            />
            <Button variant="contained" color="primary" onClick={() => checkStatus()}>
                Check status
            </Button>
            <>
                {
                    error ? <Typography>{error}</Typography>: ''
                }
            </>
            <>
                {
                    showFitech && gradingData ? <FITechPointsTable data={gradingData} />: ''
                }
            </>
            <>
                {
                    showProgrammingLanguages && gradingData ? <ProgrammingLanguagesPointsTable data={gradingData} />: ''
                }
            </>            
            {
                gradingData &&
                    <Typography variant="h3">
                        Project review legend: (#given, self score, #received peers, avg score peers, #received admin, avg score admin)
                    </Typography>
            }
            <>
                {
                    showWebSoftwareDevelopment && gradingData ? <WebSoftwareDevelopmentSummaryTable data={gradingData} />: ''
                }
            </>
            <>
                {
                    showDeviceAgnosticDesign && gradingData ? <DeviceAgnosticDesignSummaryTable data={gradingData} />: ''
                }

            </>
            <>
                {
                    showDesigningAndBuildingScalableWebApplications && gradingData ? <DesigningAndBuildingScalableWebApplicationsSummaryTable data={gradingData} />: ''
                }
            </>
            <>
            {
                showWsd2 && gradingData ? 
                <WebSoftwareDevelopment20SummaryTable data={gradingData} />: ''
            }
            </>
            
        </>
    );
};

const CourseGradingPageWithRestriction = (props: JSX.IntrinsicAttributes) => (
    <Box mx={2}>
        <RestrictedByRole role="ADMIN">
            <CourseGradingPage />
        </RestrictedByRole>
    </Box>
);

export default CourseGradingPageWithRestriction;
